import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const pStyle = "text-lg md:text-xl md:px-12 mb-6"
const dateStyle = "text-xs md:px-12 mb-6"

const PageBlogPost3 = () => {
  const data = useStaticQuery(graphql`
    query PageBlogPost3Query {
      imgHero: file(relativePath: { eq: "backgrounds/blog-6.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  return (
    <Layout>
      <Seo
        title="The Price of a Bad Business Reputation"
        description="Brand reputation and perception is an important and often over looked aspect of business's success."
        image={imgSrc}
      />
      <HeroSection
        title="THE PRICE OF A BAD BUSINESS REPUTATION"
        imgSrc={imgHero}
      />

      <div className="py-20">
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
          <p className={dateStyle}>October 24, 2018</p>
          <p className={pStyle}>Brand reputation and perception is an important and often over looked aspect of business's success. From word of mouth to online reviews, a negative brand perception can effect the success of your business.</p>
          <p className={pStyle}><a href="https://smallbusiness.patriotsoftware.com/price-bad-business-reputation/" target="_blank" rel="noopener noreferrer" className="text-site-red underline">Read more</a></p>
        </div>
      </div>
    </Layout>
  )
}

export default PageBlogPost3
