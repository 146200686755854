import * as React from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSection = ({ imgSrc, customImgHeight, noTitle, title, withButton, whiteButton, buttonName, buttonLink }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <div className={`${withButton ? "" : "-z-10"} relative`} style={{ display: "grid", background: "#423E42" }}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
          height: 400,
        }}
        alt=""
        image={imgSrc ? imgSrc : imgDefault}
        formats={["auto", "webp", "avif"]}
      />

      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        {noTitle
          ?
          null
          :
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h1 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-12">
                {title ? title : "Hero Title"}
              </h1>

              {withButton
                ?
                <div className="w-full flex items-center justify-center mt-12">
                  <Link to={buttonLink ? buttonLink : "/"}>
                    {whiteButton
                      ?
                      <button className="w-full bg-white text-base md:text-lg text-site-red border border-site-red uppercase tracking-wider px-8 py-3">
                        {buttonName ? buttonName : "Button Name"}
                      </button>
                      :
                      <button className="w-full bg-site-red hover:bg-red-800 text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {buttonName ? buttonName : "Button Name"}
                      </button>
                    }
                  </Link>
                </div>
                :
                null
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default HeroSection
